import "@hotwired/turbo-rails"
import "./controllers"

window.onload = () => {
  initLinks();
}

initLinks = () => {
  const links = document.querySelectorAll('header nav a');

  links.forEach(link => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      scrollToLink(link);
    })
  })

  const hireMeLink = document.querySelector('a#hire-me-button');
  hireMeLink.addEventListener('click', (e) => {
    e.preventDefault();
    scrollToLink(hireMeLink);
  })

}

scrollToLink = (link) =>  {
  let scrollToTop = targetTopByLink(link);
  if (link.getAttribute('href') !== '#top') {
    scrollToTop = scrollToTop + headerHeight();
  }
  window.scroll({
    top: scrollToTop,
    left: 0,
    behavior: 'smooth'
  });
}

targetTopByLink = (link) => {
  const linkTop = topById(link.getAttribute('href'));
  const bodyTop = topById('#top');

  return Math.abs(linkTop - bodyTop);
}

topById = (domId) => {
  const ele = document.querySelector(domId);
  const rect = ele.getBoundingClientRect();
  return rect.top;
}

headerHeight = () =>  {
  const ele = document.querySelector('header');
  const styles = window.getComputedStyle(ele);
  return ele.offsetHeight + parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);
}
